@mixin respond-to($breakpoint) {
	//breakpoints asc
	@if $breakpoint == 'extra-small' {
		@media (min-width: 500px) {
			@content;
		}
	} @else if $breakpoint == 'small' {
		@media (min-width: 767px) {
			@content;
		}
	} @else if $breakpoint == 'medium' {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $breakpoint == 'large' {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $breakpoint == 'extra-large' {
		@media (min-width: 1400px) {
			@content;
		}
		//Added for greatvet, breakpoints desc
	} @else if $breakpoint == 'small-phone' {
		@media (max-width: 499px) {
			@content;
		}
	} @else if $breakpoint == 'mobile' {
		@media (max-width: 766px) {
			@content;
		}
	} @else if $breakpoint == 'tablet' {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $breakpoint == 'laptop' {
		@media (max-width: 1199px) {
			@content;
		}
	}@else if $breakpoint == 'desktop' {
		@media (max-width: 1199px) {
			@content;
		}
	}
}
