.single-persona {
	// intro content
	.intro {
		overflow: hidden;
		@include slideRight;

		h2 {
			color: $blue;
		}

		p {
			color: $grey_dark;
			margin-top: 0;
		}

		.row {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@include respond-to(small) {
				flex-direction: row;
			}
		}

		.column {
			@include respond-to(small) {
				width: 48%;
			}

			&:last-child {
				order: -1;

				@include respond-to(small) {
					order: 0;
				}
			}
		}
	}

	// buttons
	.button {
		align-items: center;
		display: inline-flex;
		font-size: 20px;
		font-weight: 700;
		justify-content: center;
		height: 68px;
		min-width: 166px;
		
		&:after {
			content: "\f054";
			font-family: "Font Awesome 5 Pro";
			font-size: 13px;
			font-weight: 400;
			line-height: 1;
			margin-left: 10px;
			padding-top: 1px;
		}
	}

	// shape below section headers
	.arrow-point {
		min-height: 25px;
		position: relative;

		svg {
			position: absolute;
		}
	}

	// secondary content
	.content-area {
		background-position: center top;
		background-size: cover;

		.background-container {
			background-position: center top;
			background-size: cover;
		}

		h2 {
			color: $white;
			
			span {
				color: $blue;
			}
		}

		&:not(.repeated) {
			text-align: center;

			h2 {
				padding-top: 120px;
			}

			p {
				color: $white;
			}

			.button {
				margin: 40px 0 75px;

				@include fadeIn;
				
				@include respond-to(small) {
					min-width: 332px;
				}
			}
		}

		&.has-background {
			.list {
				h3 {
					color: $white;
				}

				p {
					color: $white;
				}
			}
		}
		
		&.repeated {
			h2 {
				background: $blue;
				margin: 0;
				padding-bottom: 35px;
				padding-top: 70px;
				position: relative;
			}
		}

		.pre-list-copy {
			color: $grey_dark;
			margin: 70px 0 -48px;
			text-align: center;
		}

		.list {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			font-weight: 700;
			justify-content: space-around;
			list-style: none;
			margin: 0;
			padding: 118px 0 0;

			@include respond-to(small) {
				flex-direction: row;
			}

			.row {
				align-items: center;
				display: flex;
				margin-bottom: 5px;
			}
			
			p {
				color: $grey_dark;
				font-size: 20px;
				margin: 0;
			}

			li {
				margin-bottom: 50px;

				@include respond-to(small) {
					margin-bottom: 83px;
					width: 5.5 / 12 * 100%;
				}

				&.col-width-3 {
					@include respond-to(small) {
						width: 33.3%;
					}
				}
			}

			h3 {
				color: $blue_dark;
				display: inline-block;
				font-size: 36px;
				margin: 0;
				text-align: left;
				text-transform: none;
			}

			img {
				margin-right: 12px;
				width: 51px;
			}

			.button {
				margin-top: 18px;
			}
		}
	}

	// scroll animations
	#content-area-2 {
		overflow: hidden;
		
		.container {
			@include slideLeft;
		}
	}

	// case study
	.case-study {
		background: $blue_dark;
		padding-bottom: 80px;
		
		.container {
			background: $white;
			padding: 25px 30px 28px;

			@include respond-to(small) {
				padding: 50px 60px 57px;
			}
		}

		.section-heading {
			color: $blue;
			margin: 0;
			padding: 80px 0 35px;
			text-transform: uppercase;
		}

		.title,
		.url {
			text-transform: none;
		}

		.title {
			color: $blue_dark;
			margin: 0 0 15px;
		}

		.url {
			font-size: 24px;
			margin: 0;
			
			a {
				color: $blue;
			}
		}

		.case-study-content {
			color: $grey_dark;
			margin-top: 45px;

			h2 {
				font-size: 32px;
				text-align: left;
				text-transform: none;
			}
		}

		.featured-asset {
			background-image: url(/wp-content/themes/vetmethod/assets/images/screen.jpg);
			background-size: contain;
			background-repeat: no-repeat;
			margin: auto;
			position: relative;
			width: 300px;
			height: 250px;
			
			
			@include respond-to(small) {
				float: left;
				height: 396px;
				margin-right: 45px;
				width: 488px;
			}

			.inner-image {
				background-size: cover;
				background-position: center;
				height: 153px;
				width: 269px;
				position: absolute;
				left: 16px;
				top: 15px;
				
				@include respond-to(small) {
					height: 248px;
					width: 436px;
					left: 26px;
					top: 25px;
				}
			}
		}
	}

	// case study gallery
	.gallery {
		h2 {
			background: $blue_dark;
			color: $white;
			margin: 125px 0 75px;
			padding: 50px 0;
		}

		.slick-slide {
			border: 1px solid $grey_dark;
			margin: 0 15px;
			transition: transform 0.25s linear;

			@include respond-to(small) {
				margin: 0;
			}
		}

		.slick-track {
			padding: 100px 0;
		}

		.slick-center.slick-active {
			transform: scale(1.25);
		}

		.slick-next,
		.slick-prev {
			background-color: transparent;
			background-size: contain;
			border: none;
			font-size: 0;
			height: 70px;
			outline: none;
			position: absolute;
			width: 70px;
			top: 50%;
			transform: translateY(-50%);
		}
		
		.slick-next {
			background-image: url(/wp-content/themes/vetmethod/assets/images/chevron-button.png);
			right: -60px;

			@include respond-to(small) {
				right: -80px;
			}
		}
		
		.slick-prev {
			background-image: url(/wp-content/themes/vetmethod/assets/images/chevron-button-left.png);
			left: -60px;
			
			@include respond-to(small) {
				left: -80px;
			}
		}

		.button {
			align-items: center;
			background: $blue_dark;
			display: flex;
			font-weight: 700;
			height: 68px;
			justify-content: center;
			margin: auto;
			padding: 0 15px;
			width: 215px;

			&:after {
				content: "\f054";
				display: inline-block;
				font-family: 'Font Awesome 5 Pro';
				font-size: 14px;
				font-weight: 400;
				margin: 2px 0 0 15px;
			}
		}
	}

	// testimonials
	.testimonials {
		padding-top: 60px;
		padding-bottom: 50px;
		
		.container {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@include respond-to(small) {
				flex-direction: row;
			}
		}


		.column {
			&:first-child {
				@include respond-to(small) {
					text-align: center;
					width: 5 / 12 * 100%;
				}
			}

			&:last-child {
				@include respond-to(small) {
					width: 7 / 12 * 100%;
				}
			}
    }
    
    .name {
			color: $blue_dark;
			font-size: 30px;
		}
		
		.name,
		.business,
		.url,
		.quote {
			font-weight: 700;
		}

		.business,
		.url {
			font-size: 20px;
		}

		.business {
			color: $grey_dark;
			text-transform: uppercase;
		}

		.url {
			color: $blue;
		}

    .quote {
      color: $grey_dark;
      font-size: 20px;
      font-weight: 700;
			line-height: 1.8;
      margin-bottom: 40px;
    }

		.video-embed.placeholder {
			background-image: url(/wp-content/themes/vetmethod/assets/images/video_placeholder_2.jpg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 217px;
		}

		.button {
			&:hover {
				background: $blue_dark;
			}
		}
	}

	// footer banner
	.footer-banner {
		background: $blue;
		text-align: center;

		@include respond-to(small) {
			align-items: center;
			display: flex;
			justify-content: center;
			min-height: 230px;
			text-align: left;
		}

		.button {
			align-items: center;
			background: $blue_dark;
			display: inline-flex;
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 15px;
			min-height: 68px;
			
			@include respond-to(small) {
				margin-bottom: 0;
				margin-left: 60px;
			}

			&:after {
				content: "\f054";
				font-family: "Font Awesome 5 Pro";
				font-size: 13px;
				font-weight: 400;
				line-height: 1;
				margin-left: 10px;
				padding-top: 1px;
			}
		}
	}
}
