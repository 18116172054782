// mobile style
// mobile nav
// for all templates except gated
.header {
  @include respond-to(small) {
    padding: 15px 15px 0;
  }
  .nav-main {
    background: $blue;
    left: 0;
    position: fixed;
    top: -100%;
    transition: top 0.35s ease-out;
    width: 100%;
    z-index: 5;

    &.active {
      top: 0;
    }

    ul {
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 50px;
      text-align: center;
    }

    li {
      margin: 0 0 0.5em;

      
      @include respond-to(small) {
        margin: 0 0 0.75em;
        white-space: nowrap;
      }
    }
    
    a {
      color: $white;
      font-size: 24px;
      font-weight: normal;

      @include respond-to(small) {
        font-size: 38px;
      }

      &:hover {
        color: $black;
      }
    }

    .close,
    .back {
      position: absolute;
      right: 0;
      top: 0;
      color: $white;
      cursor: pointer;
      font-size: 24px;
      margin: 50px 25px 25px;
    }

    .back {
      background: transparent;
      border: none;
      padding: 0;
    }
  }
}

.header {
  position: absolute;
  width: 100%;
  z-index: 101;

  &.sticky {
    background: rgba($white, 0.95);
    position: fixed;
    padding:0;
    top:0;

    .search{
      display:none;
      input{
          margin-left:15px;
      }
    }

    .container {
      align-items: flex-end;
      height: 100px;
    }

    .column:last-child {
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;

      .nav-main {
        order: -1;
        padding-bottom:20px;
      }
    }

    .phone-number {
      display: none;
    }

    li {
      margin-bottom: 0;

      a {
        margin-top: 0;
      }
    }

    .logo {
      max-width: 100px;
    }

    .nav-main a {
      color: $white;
      display: inline-block;
      padding-bottom: 10px;

      @include respond-to(small) {
        color: $grey_dark;
      }
    }
  }

  .container {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: space-between;
    
    @include respond-to(small) {
      height: 150px;
    }
  }

  .column {
    &:first-child {
      width: 75%;

      @include respond-to(small) {
        width: 5 / 12 * 100%;
      }
    }

    &:last-child {
      @include respond-to(small) {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        width: 7 / 12 * 100%;
      }
    }
  }

  .logo {
    display: inline-block;
    max-width: 425px;
  transition: all 0.25s ease-out;

    @include respond-to(small) {
      margin-top: 70px;
      webkit-filter: drop-shadow(4px 4px 6px rgba(0,0,0,0.4));
      filter: drop-shadow(4px 4px 6px rgba(0,0,0,0.4));
    }
  }

  .phone-number {
    display: none;
    font-size: 24px;
    font-weight: normal;
    color:$blue_dark;

    @include respond-to(small) {
      display: inline-block;
      /*margin-right: 8px;*/
    }
  }

  .nav-button {
    border: 1px solid;
    cursor: pointer;
    display: inline-block;
    margin-left: 20px;
    padding: 14px 22px;
    font-size: 25px;

    @include respond-to(small) {
      display: none;
    }
  }

  .fa-search {
    display: none;
    color:$blue_dark;
    font-size:22px;
    margin-left:20px;
  }
}


// desktop style (w/ phone)
.header {
  .nav-main {
    
    @include respond-to(small) {
      display: none;
      background: transparent;
      top: auto !important;
      display: block;
      left: auto;
      position: relative;
      width: auto;
      margin-top: 30px;
    }
    
    ul {
      display: flex;
      list-style: none;

      @include respond-to(small) {
        flex-direction: row;
        padding: 0;
      }

      ul {
        background: $blue;
        height: 100%;
        flex-direction: column;
        position: absolute;
        left: 100%;
        top: 0;
        transition: left 0.35s ease-in;
        width: 100%;

        @include respond-to(small) {
          background: $white;
          box-shadow: 1px 1px 2px 0 rgba($grey_dark, 0.5);
          display: none;
          height: auto;
          left: -25px;
          padding: 5px 15px 10px 0;
          top: auto;
          text-align: left;
          width: 300px;
        }

        &.active {
          left: 0;

          @include respond-to(small) {
            left: -25px;
          }
        }

        li {
          line-height: 1.25;
          margin: 0 0 0.5em;

          @include respond-to(small) {
            margin: 0;
          }
        }

        a {
          padding-left: 25px;
        }

        a:hover:before {
          @include respond-to(small) {
            content: "";
            border-left: 5px solid $green;
            display: inline-block;
            left: 0;
            position: absolute;
            height: 100%;
          }
        }
      }
    }

    li {
      font-weight: 700;

      @include respond-to(small) {
        margin-right: 20px;
        position: relative;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        ul {
          display: flex;
        }

        &>a {
          color: $black;
        }
      }
    }

    a {
      @include respond-to(small) {
        color: $blue_dark;
        font-size: 20px;
        transition: color 0.25s linear;
      }
    }

    .close {
      @include respond-to(small) {
        display: none;
      }
    }
  }
}


// search
.header {
  .fa-search {
    display: inline-block;
    font-size: 24px;
    margin-left: 7px;
  }


  .phone-and-search {
    align-items: center;
    display: flex;
    font-size:24px;
  }

  .search {
    display: none;

    @include respond-to(small) {
      display: none;
    }

    &:hover {
      input[type=search] {
        background: $white;
        width: 200px;
      }
    }

    form {
      align-items: center;
      display: flex;
    }

    input {
      background: transparent;
    }

    input[type=search] {
      border: none;
      border-radius: 4px;
      color: $black;
      font-size: 14px;
      font-weight: 700;
      height: 24px;
      outline: 0;
      padding: 1px 6px;
      width: 0;
      transition: width ease-in 0.5s;
    }

    button {
      background: none;
      border: none;
      color: $black;
      padding: 0;
    }
  }
}

.page-template-gated {
  .header {
    .nav-button,
    .fa-search,
    .main-nav {
      display: none;
    }
  }
}

@include respond-to(mobile) {

  .header{
    background-color:$blue_dark;

    .container{
      padding:0;
      
      .column{
        display: flex;

        .search {
          &:hover {
            input[type=search] {
              background: $grey_lighter;
              width: 150px;
            }
          }
        }

        input{
          color:$blue_dark;
          background-color:$grey_light;
        }

        .fa-search{
          color:$blue_dark;
        }
      }
    }

  .logo {
    max-width:120px;
    margin-top: 90px;
    margin-left:20px;
  }

  .nav-button{
    color:$blue;
    border:none;
  }

  }
}