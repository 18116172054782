.page-template-template-careers {
	overflow: hidden;
	
	.intro-content {
		color: $grey_dark;
		padding: 50px 15px 50px;
		text-align: center;
		
		@include slideRight;
		
		@include respond-to(small) {
			padding: 145px 0 155px;
		}
	}

	.big-vid {
		display: flex;
		width: 100%;

		iframe {
			width: 100%;
		}
	}

	.jobs {
		background: $blue_dark;
		color: $white;
		padding: 90px 0 80px;
		text-align: center;

		.container {
			@include respond-to(small) {
				@include slideLeft;
			}
		}

		p {
			font-weight: 400;
		}
		
		h2 {
			color: $green;
			font-size: 60px;
			text-align: center;
			text-transform: uppercase;
		}
	}

	.job {
		align-items: center;
		background: $green;
		border: 1px solid $grey;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		min-height: 80px;
		justify-content: flex-start;
		margin: 35px auto;
		padding: 20px;
		position: relative;

		@include respond-to(small) {
			width: 771px;
		}

		&:after {
			content: "\f067";
			display: inline-block;
			font-family: 'Font Awesome 5 Pro';
			font-size: 28px;
			font-weight: 400;
			padding: 0 25px 0 0;
			position: absolute;
			right: 0;
		}

		&.active {
			&:after {
				content: "\f068";
			}
		}

		.title {
			font-size: 30px;
			font-weight: 700;
		}
	}

	.description {
		display: none;
		text-align: left;
	}

	.banner {
		background: $green;

		.container {
			justify-content: center;
		}

		h2 {
			font-size: 36px;
			text-align: center;
			width: 8 / 11 * 100%;
		}

		.button {
			align-items: center;
			background: $blue_dark;
			display: flex;
			font-weight: 700;
			justify-content: center;
			padding: 20px 0;
			text-align: center;
			max-width: 166px;
			width: 166px;
			
			&:hover {
				background: $white;
				color: $blue_dark;
				width: 2 / 11 * 100%;
			}

			&:after {
        content: "\f054";
        font-family: "Font Awesome 5 Pro";
        font-size: 13px;
        font-weight: 300;
        line-height: 1;
				margin-left: 10px;
				padding-top: 1px;
      }
		}
	}

	.promo-area {
		position: relative;

		@include slideRight;

		.container {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			max-width: none;
			padding: 0;

			@include respond-to(large) {
				flex-direction: row;
			}
		}

		.column {
			@include respond-to(large) {
				width: 50%;
			}

			&:first-child {
				order: 2;
				padding: 0 30px;
				
				@include respond-to(small) {
					padding: 0 50px;
				}

				@include respond-to(large) {
					padding: 0 0 0 85px;
				}
			}
			
			&:last-child {
				left: 0;
				order: 1;
				height: 100%;

				img {
					max-height: 100%;
				}
			}
		}

		.column-content {
			@include respond-to(large) {
				max-width: 496px;
			}
		}

		.images-collage {
			display: flex;
			flex-wrap: wrap;

			.image {
				width: 25%;

				img {
					display: block;
				}
			}
		}

		h2 {
			text-align: left;
		}

		.copy {
			p {
				color: $grey_dark;
				line-height: 1.5;
			}
		}
	}

	.list-section {
		background-repeat: no-repeat;
		background-size: cover;
		padding: 70px 25px 85px;

		h2 {
			color: $green;
			font-size: 60px;
			margin-top: 0;
		}

		ul {
			display: grid;
			list-style: none;
			margin: 0 auto;
			padding: 0;
			grid-column-gap: 10px;
			grid-template-columns: repeat(2, 1fr);
			
			@include respond-to(small) {
				grid-template-columns: repeat(3, 1fr);
			}

			@include respond-to(large) {
				max-width: 1110px;
			}
		}

		li {
			align-items: center;
			color: $white;
			display: inline-flex;
			justify-content: flex-start;
			min-height: 72px;

			p {
				margin: 0;
			}
		}

		.icon {
			margin-right: 10px;
			text-align: center;
			min-width: 45px;
			width: 45px;
			
			@include respond-to(small) {
				min-width: 60px;
				width: 60px;
			}
		}
	}

	#mega-quote {
		@include fadeIn;
	}

	.mega-quote {
		display: flex;
		height: 375px;
		
		@include respond-to(medium) {
			height: 375px;
		}

		@include respond-to(large) {
			height: 450px;
		}

		img {
			display: block;
		}
		
		.column {
			background-position: center;
			background-size: cover;
			
			@include respond-to(small) {
				width: 33%;
			}

			&:nth-child(2) {
				align-items: center;
				color: $green;
				display: flex;
				font-size: 32px;
				font-weight: 700;
				justify-content: center;
				text-align: center;
				
				@include respond-to(extra-large) {
					font-size: 48px;
				}
			}

			&:first-child,
			&:last-child {
				display: none;

				@include respond-to(small) {
					display: block;
				}
			}
		}
	}

	.footer-banner {
		background: $green;

		@include respond-to(small) {
			align-items: center;
			display: flex;
			justify-content: center;
			min-height: 230px;
		}

		.button {
			align-items: center;
			background: $blue_dark;
			display: flex;
			font-size: 20px;
			font-weight: 700;
			margin: auto;
			min-height: 68px;
			width: 166px;
			
			@include respond-to(small) {
				margin-right: 0;
				margin-left: 60px;
				width: auto;
			}

			&:hover {
				background: $white;
				color: $blue_dark;
			}

			&:after {
        content: "\f054";
        font-family: "Font Awesome 5 Pro";
        font-size: 13px;
        font-weight: 300;
        line-height: 1;
				margin-left: 10px;
				padding-top: 1px;
      }
		}
	}
}