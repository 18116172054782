.page-template-template-testimonials {
	.testimonials {
		@include fadeIn;
	}

	.testimonial {
		padding: 30px 0 25px;

		.container {
			display: flex;
			flex-direction: column;
		}
		
		.column {
			text-align: center;
		}

		.name {
			color: $blue_dark;
			font-size: 30px;
		}
		
		.name,
		.business,
		.url,
		.quote {
			font-weight: 700;
		}

		.business,
		.url {
			font-size: 20px;
		}

		.business {
			color: $grey_dark;
			text-transform: uppercase;
		}

		.url {
			a {
				color: $green;
			}
		}

		.quote {
			color: $grey_dark;
			font-size: 20px;
			line-height: 1.8;
			margin: 0 0 25px;
		}

		&:nth-child(odd) {
			background: $grey_light;
		}

		.image,
		.video-embed {
			margin: 0 0 25px;
		}
	}
}
