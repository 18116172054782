.single-post {
	.article-body {
		color: $grey_dark;

		.container {
			a {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.hero {
		@include respond-to(small) {
			height: 538px;
		}

		.heading {
			color: $blue_dark;
			font-size: 60px;
			line-height: 1.375;
			margin: 0;
			text-transform: none;
		}
		
		.heading,
		.blog-author {
			max-width: 825px;
		}

		.blog-author {
			color: $grey_dark;
			display: flex;
			font-size: 30px;
			font-weight: 700;
			margin: 35px 0 0;

			.column {
				text-align: center;
			}

			a {
				color: $green;
			}

			img {
				border-radius: 100%;
				margin-right: 20px;
				max-width: 65px;
			}

			.author {
				margin-top: 10px;
			}

			.date {
				font-size: 16px;
			}
		}
	}

	.paging {
		.container {
			align-items: center;
			border-bottom: 1px solid $grey;
			display: flex;
			justify-content: center;
			margin: 50px auto;
			padding: 0 0 50px;
		}

		.separator {
			margin: 0 10px;
		}

		a {
			color: $green;
			font-size: 24px;
			font-weight: 700;

			&:hover {
				text-decoration: underline;
			}
		}

		.back a {
			&:before {
				color: $grey_dark;
				content: "\f053";
				display: inline-block;
				font-family: "Font Awesome 5 Pro";
				font-size: 14px;
				font-weight: 400;
				margin-right: 5px;
				position: relative;
				top: -2px;
			}
		}

		.next a {
			&:after {
				color: $grey_dark;
				content: "\f054";
				display: inline-block;
				font-family: "Font Awesome 5 Pro";
				font-size: 14px;
				font-weight: 400;
				margin-left: 5px;
				position: relative;
				top: -2px;
			}
		}
	}

	.author-info {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin: 0 auto 95px;
		width: 10 / 12 * 100%;

		@include respond-to(small) {
			align-items: flex-start;
			flex-direction: row;
		}

		img {
			border-radius: 100%;
			margin-right: 40px;
			max-width: 160px;
		}

		.author-name {
			color: $blue_dark;
			font-size: 30px;
			font-weight: 700;
			margin: 0;
			text-transform: uppercase;
		}

		p {
			margin: 0 0 1em;
		}
	}

	.signup-banner {
		background: $green;
		color: $white;
		padding: 25px 0;

		h2 {
			font-size: 36px;
			line-height: 48px;
			margin: 0 0 10px;
		}
		
		h2,
		.embedded-form {
			margin-left: auto;
			margin-right: auto;
			max-width: 840px;
			text-align: center;
		}
	}
}
