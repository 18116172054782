@mixin slideRight {
	left: -25%;
	transition: all 0.25s linear;
	opacity: 0;
	position: relative;

	&.active {
		left: 0;
		opacity: 1;
	}
}

@mixin slideLeft {
	right: -25%;
	transition: all 0.25s linear;
	opacity: 0;
	position: relative;

	&.active {
		right: 0;
		opacity: 1;
	}
}

@mixin fadeIn {
	opacity: 0;
	transition: opacity 0.25s ease-out;

	&.active {
		opacity: 1;
	}
}
