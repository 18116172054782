.hero {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 150px;
  position: relative;

  @include respond-to(mobile){
    background-position-y:60px !important;
  }

  @include respond-to(small) {
    min-height: 66vh;
  }

  .heading,
  .cta {
    text-transform: uppercase;
  }

  .heading {
    font-size: $m-subhead;
    line-height: 1.2;
    margin-bottom: 60px;
    padding: 0 30px;
    text-align: center;
    color: $blue_dark;
    font-weight:normal;

      span {
        margin-top:10px;
        color: $blue;
        display: block;
        font-size: $m-heading;
        font-weight:normal;
        line-height:1;
        font-weight:500;
      }

    }
  @include respond-to(small){
    .heading {
      font-size:$d-hero-subhead;
      span{
        font-size:$d-hero-heading;
      }
    }

  }

  .copy {
    font-size: 26px;
    line-height: 1.46;
    margin-bottom: 15px;
    text-align: center;

    @include respond-to(large) {
      max-width: 900px;
    }

    p {
      font-weight: 400;
    }
  }

  .copy-link {
    position: relative;
    width: 119px;

    &:hover {
      background-position: bottom;
    }
  }

  .cta-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 125px;
    width: 100%;

    @include respond-to(medium) {
      flex-direction: row;
    }

    @include respond-to(large) {
      max-width: 1138px;
    }
  }

  .cta {
    color: $white;
    font-size: 30px;
    margin: 0 auto 150px;
    max-width: 350px;
    padding: 60px 0 25px;
    position: relative;
    text-align: center;
    transition: transform 0.25s linear;
    width: 100%;
    
    @include respond-to(medium) {
      margin: 0;
      width: 33%;
    }

    &:hover {
      .icon {
        background-position: bottom;
      }
    }

    &:first-child {
      background: $blue;
    }

    &:nth-child(2) {
      background: $blue_dark;
    }

    &:last-child {
      background: $blue;
    }

    .icon {
      background-position: top;
      background-repeat: no-repeat;
      background-size: 125px;
      height: 125px;
      left: 50%;
      max-width: 125px;
      position: absolute;
      top: -90px;
      transform: translateX(-50%);
    }

    .copy {
      p {
        font-weight: 700;
        line-height: 1.1;
        margin: 0;
      }
    }

    span {
      font-size: 50px;
    }
  }
}


// home version

.home {
  .hero {

/*    background-size: cover;
    justify-content: flex-end;
    padding-top: 150px;*/
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 150px;
    position: relative;


    @include respond-to(small) {
      min-height: 100vh;
    }

    .heading-and-copy {
      background-position: center;
      background-size: cover;
      padding-top: 150px;
      padding-left: 30px;
      padding-right: 30px;

      @include respond-to(small) {
        background-size: 0;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}


// chevron'ed versions
// careers, onboarding, team
.page-template {
  &.page-template-template-careers,
  &.page-template-team,
  &.page-template-template-onboarding {
    .hero {
      h1 {
        /*margin: 0 0 -15px;*/
      }
    }

    .copy-link {
      display: block !important;
    }
  }
}

// hide chevron on all other
// secondary page templates
// and blog posts
.page-template,
.single-post {
  .hero {
    .copy-link {
      display: none;
    }
  }
}

// content jump anchor
// height must match
// height of sticky header
.hero {
  #content-start {
    bottom: 0;
    height: 100px;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}
