.single-landing_page {
	.hero {
		padding-bottom: 48px;

		.heading {
			color: $blue_dark;
			font-size: 60px;
			margin-bottom: 20px;
			text-transform: none;
		}
	}

	.intro-content {
		margin-bottom: 60px;
	}

	h2 {
		color: $blue_dark;
		margin: 95px 0 0;
		text-transform: none;
	}

	.article-body {
		color: $grey_dark;
	}

	.author-section {
		color: $grey_dark;
		display: flex;
		font-size: 30px;
		font-weight: 700;
		justify-content: center;
		margin: 15px 0 50px;

		.column {
			text-align: center;
		}

		a {
			color: $blue;
		}

		img {
			border-radius: 100%;
			margin-right: 20px;
			max-width: 65px;
		}

		.author {
			margin-top: 10px;
		}

		.date {
			font-size: 16px;
		}
	}

	// form
	.form-embed {
		background: $blue;;
		padding: 55px 0 62px;
	}

	._form-title,
	._form-label {
		font-weight: 700;
		text-transform: uppercase;
	}

	._form-title {
		font-size: 30px;
		color: $blue;
		margin-bottom: 25px;
		text-align: center;
	}
	
	._form_element {
		width: 48%;

		&._clear {
			width: 100%;
		}

		input {
			border: 1px solid $grey_dark;
			font-size: 18px;
			height: 60px;
			margin-bottom: 60px;
			padding: 5px 10px;
			width: 100%;
		}
	}

	._form-label {
		color: $black;
		display: block;
		font-size: 20px;
		margin-bottom: 3px;
	}

	._button-wrapper {
		text-align: center;
		width: 100%;
	}

	._form-content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: auto;

		@include respond-to(small) {
			max-width: 975px;
		}
	}

	._form-branding {
		display: none;
	}

	// buttons
	button,
	.button {
		align-items: center;
		display: inline-flex;
		font-weight: 700;
		font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
		height: 68px;
		justify-content: center;
		min-width: 170px;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		&:after {
      content: "\f054";
      display: inline-block;
      font-family: 'Font Awesome 5 Pro';
      font-size: 14px;
      font-weight: 400;
      margin: 1px 0 0 8px;
    }
	}

	.gated-content {
		.button {
			display: flex;
			margin: 0 auto 25px;
			width: 240px;
		}
	}
}
