.page-template-template-process {
	.article-body {
		color: $grey_dark;
	}

	.article-h1 {
		color: $green;
		font-size: 60px;
		margin: 100px 0 0;
	}

	.intro {
		color: $black;
		margin-bottom: 40px;
		text-align: center;
	}

	.step-column {
		@include respond-to(small) {
			width: 3 / 12 * 100%;
		}
	}

	.step {
		align-items: center;
		display: flex;
		flex-direction: column;
		font-weight: 700;
		justify-content: space-between;
		margin-bottom: -50px;
		padding-bottom: 200px;
		position: relative;
		text-transform: uppercase;
		
		@include respond-to(small) {
			flex-direction: row;
		}
		
		@include respond-to(large) {
			padding-bottom: 254px;
		}

		&:nth-child(1) {
			padding-top: 100px;
		}

		&:nth-child(3) {
			padding-top: 225px;
		}

		&:nth-child(4) {
			padding-top: 75px;
		}

		&:nth-child(5) {
			padding-top: 75px;
		}

		&:nth-child(odd) {
			.step-badge {
				background-color: $blue_dark;
			}

			.step-number {
				border-color: $blue_dark;
			}
		}

		&:nth-child(even) {
			@include respond-to(small) {
				flex-direction: row-reverse;
			}

			.step-badge {
				background-color: $green;
			}

			.step-number {
				border-color: $green;
			}
		}

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 100px;
		}
	}

	.step-badge {
		align-items: flex-end;
		display: flex;
		height: 250px;
		justify-content: center;
		max-width: 100%;
		padding: 10px 10px 50px;
		position: relative;
		text-align: center;
		width: 300px;
		
		@include respond-to(large) {
			height: 300px;
		}
	}

	.step-number {
		align-items: center;
		background-color: $white;
		border-radius: 100%;
		border: 3px solid;
		display: flex;
		flex-direction: column;
		height: 150px;
		left: calc(50% - 75px);
		justify-content: center;
		position: absolute;
		text-align: center;
		top: -75px;
		width: 150px;
		
		@include respond-to(large) {
			left: calc(50% - 100px);
			height: 200px;
			width: 200px;
		}

		.label,
		.number {
			line-height: 1;
		}

		.label {
			font-size: 28px;
			
			@include respond-to(large) {
				font-size: 36px;
			}
		}

		.number {
			color: $black;
			font-size: 48px;
			
			@include respond-to(large) {
				font-size: 60px;
			}
		}
	}

	.step-name {
		color: $white;
		font-size: 28px;
		
		@include respond-to(large) {
			font-size: 36px;
		}
	}

	.step-sub-items {
		display: flex;
		// flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-around;
		width: 100%;
		
		@include respond-to(small) {
			width: 8 / 12 * 100%;
			// flex-direction: row;
		}
	}

	.sub-item {
		margin-top: 25px;
		text-align: center;
		width: 50%;
		
		@include respond-to(small) {
			width: 2.5 / 9 * 100%;
		}

		.copy {
			font-size: 28px;
			text-transform: none;
			
			@include respond-to(large) {
				font-size: 36px;
			}
		}

		.icon {
			display: inline-block;
			margin-bottom: 15px;

			&.placeholder {
				color: $blue_dark;
				border: 2px solid;
				border-radius: 100%;
				height: 122px;
				width: 122px;
			}
		}
	}

	.nav-banner {
		ul {
			flex-direction: row;
			height: 150px;
			
			@include respond-to(medium) {
				height: 230px;
			}
		}

		li {
			font-size: 28px;
			width: 50%;

			@include respond-to(medium) {
				font-size: 45px;
			}
			
			@include respond-to(large) {
				font-size: 60px;
			}

			&:first-child {
				border-right: 2px solid $white;
			}
		}

		a {
			padding: 0 15px;

			@include respond-to(medium) {
				padding: 0;
			}
		}

		a:after {
			font-size: 24px;
			font-weight: 500;
			padding-top: 6px;

			@include respond-to(medium) {
				font-size: 32px;
			}
			
			@include respond-to(large) {
				font-size: 38px;
			}
		}
	}

	// arrows
	.arrow-line-container {
		display: none;

		@include respond-to(small) {
			display: block;
			position: absolute;
			bottom: 0;
		}
	}

	.step:nth-child(1) {
		.arrow-line-container {
			bottom: auto;
			left: 120px;
			height: 249px;
			top: 350px;
			width: 50%;
			
			@include respond-to(large) {
				bottom: 0;
				left: 150px;
				top: auto;
				width: 685px;
			}
		}
	}

	.step:nth-child(2) {
		.arrow-line-container {
			right: 125px;
			top: 250px;
			width: 76%;
			
			@include respond-to(large) {
				bottom: -40px;
				right: 150px;
				top: auto;
				width: 920px;
			}
		}
	}
	
	.step:nth-child(3) {
		.arrow-line-container {
			top: 475px;
			left: 120px;
			width: 76%;
			
			@include respond-to(large) {
				bottom: 61px;
				left: 150px;
				top: auto;
				width: 921px;
			}
		}
	}
	
	.step:nth-child(4) {
		.arrow-line-container {
			right: 125px;
			top: 405px;
			width: 76%;
			
			@include respond-to(large) {
				bottom: 116px;
				right: 150px;
				top: auto;
				width: 921px;
			}
		}
	}

	.step:last-child {
		.arrow-line-container {
			display: none;
		}
	}

	.arrow-line {
		stroke-width: 5px;
		stroke-dasharray: 1000;
		stroke-dashoffset: 1000;
	}

	#line-1 {
		stroke-dasharray: 910;
		stroke-dashoffset: 910;
	}
	
	#line-2 {
		stroke-dasharray: 1195;
		stroke-dashoffset: 1195;
	}
	
	#line-3 {
		stroke-dasharray: 1085;
		stroke-dashoffset: 1085;
	}
	
	#line-4 {
		stroke-dasharray: 1095;
		stroke-dashoffset: 1095;
	}
	
	
	.activated {
		.arrow-line {
			animation: draw 0.75s linear forwards;
		}

		.arrow {
			animation: appear 0.75s linear forwards;
			opacity: 0;
		}
	}
	
	@keyframes draw {
		to {
			stroke-dashoffset: 0;
		}
	}
	
	@keyframes appear {
		99% {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	
}
