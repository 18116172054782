.single-project,
.single-logo_project,
.single-template_project {
	.title {
		color: $blue_dark;
		margin: 105px 0 20px;
	}

	.url {
		color: $blue;
		font-size: 36px;
		margin: 0 0 45px;
		text-transform: none;
	}

	.content {
		color: $grey_dark;

		h2 {
			color: $blue_dark;
			font-size: 24px;
			text-align: left;
			margin: 55px 0 5px;
		}

		p {
			font-size: 24px;
			line-height: 1.91;
			margin-top: 0;
		}

		.sidebar-image {
			float: right;

			@include respond-to(large) {
				margin-left: 35px;
				width: 369px;
			}
		}
	}

	.assets {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		margin: 50px auto 128px;

		@include respond-to(small) {
			flex-direction: row;
		}

		.asset {
			width: 2.66 / 12 * 100%;
		}
	}

	// testimonials
	.testimonial {
		padding: 30px 0 25px;

		.container {
			display: flex;
			flex-direction: column;

			@include respond-to(small) {
				flex-direction: row;
			}
		}
		
		.column {
			&:first-child {
				@include respond-to(small) {
					text-align: center;
					width: 5 / 12 * 100%;
				}
			}

			&:last-child {
				@include respond-to(small) {
					width: 7 / 12 * 100%;
				}
			}
		}

		.name {
			color: $blue_dark;
			font-size: 30px;
		}
		
		.name,
		.business,
		.url,
		.quote {
			font-weight: 700;
		}

		.business,
		.url {
			font-size: 20px;
		}

		.business {
			color: $grey_dark;
			text-transform: uppercase;
		}

		.url {
			a {
				color: $blue;
			}
		}

		.quote {
			color: $grey_dark;
			font-size: 20px;
			line-height: 1.8;
			margin: 0 0 25px;
		}

		&:nth-child(odd) {
			background: $grey_light;
		}

		.image,
		.video-embed {
			margin: 0 0 25px;
		}
	}

	// paging
	.paging {
		align-items: center;
		display: flex;
		justify-content: center;
		height: 230px;

		a {
			align-items: center;
			background: $blue;
			color: $white;
			display: flex;
			font-size: 60px;
			font-weight: 700;
			height: 100%;
			justify-content: center;
			text-align: center;
			text-transform: uppercase;
			width: 100%;

			&:hover {
				background: darken($blue, 5%);
			}
		}

		.back,
		.next {
			height: 100%;
			width: 50%;
		}

		.back {
			a {
				border-right: 1px solid $white;
			}
		}

		.next {
			a {
				&:after {
					content: "\f054";
					font-family: "Font Awesome 5 Pro";
					font-size: 36px;
					font-weight: 500;
					line-height: 1;
					margin-left: 10px;
					padding-left: 15px;
					padding-top: 7px;
				}
			}
		}
	}
}
