.footer-nav,
.footer {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
.footer-logo img{
  max-width:145px;
}

.footer-nav {
  display: none;
  background: $blue;
  box-shadow:0px 0px 10px $grey_light;
  margin-bottom:10px;

  @include respond-to(small) {
    display: block;
  }

  ul {
    align-items: center;
    display: flex;
    justify-content: space-around;
    min-height: 70px;
  }

  a {
    color: $white;
    font-size: 20px;
    font-weight: normal;
  }
  li.current-menu-item a{
    color:$blue_dark;
  }

  .close {
    display: none;
  }
}

.footer {
  background: $white;
  color: $grey_dark;
  padding: 50px 15% 40px;

  .container {
    display: flex;
    flex-direction: column;

    @include respond-to(small) {
      flex-direction: row;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    font-size: 20px;
    font-weight: normal;
  }

  li,
  a {
    color: $grey_dark;
  }
  li.current-menu-item a{
    color:$blue_dark;
  }

  .contact-info,
  .social-media {
    @include respond-to(small) {
      margin-top: 25px;
    }
  }

  .social-media {
    @include respond-to(small) {
      text-align: right;
    }

    h2 {
      font-size: 20px;
      font-weight: normal;
      margin: 35px 0 15px;
      text-align: left;
      
      @include respond-to(small) {
        margin: 0 0 15px;
        text-align: right;
      }
    }

    ul {
      display: inline-flex;
    }

    li {
      margin-left: 10px;
      max-width: 45px;
      color:$grey_dark;
    }
  }

  .column {
    @include respond-to(small) {
      padding-right: 30px;
      width: 6 / 12 * 100%;
    }
    
    &:first-child {
      margin: 0 0 25px;
      width: 75%;
      
      @include respond-to(small) {
        margin: 0;
        width: 6 / 12 * 100%;
      }
    }
  }

  .copyright {
    font-size: 20px;
    font-weight: normal;
    margin: 15px 0 0;
    
    @include respond-to(small) {
      font-size: 20px;
      text-align: right;
    }
  }
}

@include respond-to(mobile){
.footer .column{
  width: 100% !important;
  text-align: center;

  .social-media{
    text-align: center;
    h2{
      text-align: center !important;
    }

  }
}

}
