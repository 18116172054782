.home {
  // buttons
  .button {
    align-items: center;
    display: inline-flex;
    font-weight: 700;
    height: 68px;
    justify-content: center;

    &:after {
      content: "\f054";
      display: inline-block;
      font-family: 'Font Awesome 5 Pro';
      font-size: 14px;
      font-weight: 400;
      margin: 1px 0 0 8px;
    }
  }

  // promo area
  .promo-area {
    padding: 35px 0 70px;
    
    @include respond-to(small) {
      padding: 75px 0;
    }

    .heading {
      margin: 10px 0 50px;
    }

    .container {
      display: flex;
      flex-direction: column;

      @include respond-to(small) {
        flex-direction: row;
      }
    }

    .column {
      width: 100%;
    }

    .column:first-child {
      @include respond-to(small) {
        width: 7 / 12 * 100%;
      }
    }

    .column:last-child {
      @include respond-to(small) {
        padding-left: 50px;
        width: 5 / 12 * 100%;
      }
    }

    .copy {
      color: $grey_dark;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.92;
      text-align: center;
    }
  }

  // meet the founder
  .meet-the-founder {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    padding: 75px 0 80px;
    
    .container {
      display: flex;
      flex-direction: column;
      
      @include respond-to(small) {
        align-items: flex-end;
      }
    }

    .heading,
    .copy {
      @include respond-to(small) {
        width: 7 / 12 * 100%;
      }
    }

    .copy {
      color: $grey_dark;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.92;
      text-align: center;

      a {
        align-items: center;
        background: $blue_dark;
        border: 1px solid $grey_light;
        color: $white;
        display: inline-flex;
        font-size: 20px;
        font-weight: 700;
        height: 68px;
        justify-content: center;
        padding: 10px 35px;
        text-decoration: none;
        text-transform: uppercase;

        &:after {
          content: "\f054";
          display: inline-block;
          font-family: 'Font Awesome 5 Pro';
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          margin: 2px 0 0 8px;
        }
      }
    }
  }

  // testimonials
  .testimonials {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 50px;

    @include respond-to(small) {
      flex-direction: row;
    }

    .column {
      text-align: center;

      @include respond-to(small) {
        width: 50%;
      }
    }

    .quote {
      font-size: 36px;
      margin-bottom: 40px;
    }

    .video-embed.placeholder {
      background-image: url(/wp-content/themes/vetmethod/assets/images/video_placeholder_2.jpg);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
    }
  }

  //strips

  .home-strip{
    max-width: 1200px;
  }

  .st1-strip {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 80px 0  70px;
    background-color:$grey_lighter;
    padding-left:10%;

    @include respond-to(small) {
      margin: 0;
      width: 100%;
      margin-bottom: -70px !important;
    }

    h2 {
      margin-bottom: 50px;
      color:$blue;
      font-weight:normal;
      line-height:1.4;

      span {
        color: $blue_dark;
        font-weight:normal;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;

      @include respond-to(small) {
        flex-direction: row;
      }
    }

    .text-1{
      @include respond-to(small) {
        margin: 0 5% 70px;
        width: 70%;
      }
    }
    .text-2{
      background-repeat:no-repeat;
      background-size: cover;
      @include respond-to(small) {
        background-position-y: 30px;
        margin: 0;
        width: 30%;
        margin-bottom: -30px !important;
      }
    }

    .text-1, .text-2 {
      margin: 25px 0;
      position: relative;


      @include respond-to(small) {
        margin: 0;
        text-align: center;
        margin-bottom:70px;
      }

      &:first-child {
        @include respond-to(small) {
          /*padding-right: 25px;*/
        }
      }

      &:last-child {
        @include respond-to(small) {
          /*padding-left: 35px;*/
        }
      }

      &:first-child:after {
        @include respond-to(small) {
          content: "";
          display: inline-block;
          position: absolute;
          height: 98%;
          right: 0;
          top: 2%;
        }
      }

      h3 {
        color: $black;
        line-height: 1;
        margin: 0 0 25px;
      }
    }

    h3 {
      font-size: 36px;
    }

    .excerpt {
      color: $grey;
      text-align:center;
    }

    .explore-blog {
      font-weight: 700;
      margin-top: 60px;
    }
  }

  .st2-strip {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px 10% 70px 0;
    background-color: $blue_light;

    h2 {
      margin-bottom: 50px;
      font-weight: normal;
      /*font-size: 22px;*/
      color: $grey_dark;
      line-height:1.4;

      span {
        color:$white;
        font-weight: normal;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      /*overflow: hidden;*/

      @include respond-to(small) {
        flex-direction: row;
        ;
      }
    }

    .text-1{
      background-repeat:no-repeat;
      background-size: contain;
      @include respond-to(small) {
        margin: 0;
        width: 50%;
        margin-bottom: -70px !important;
        background-position-y:bottom;
      }
    }
    .text-2{
      @include respond-to(small) {
        margin: 0;
        width: 50%;
      }
    }

    .text-1, .text-2 {
      margin: 25px 0;
      position: relative;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include respond-to(small) {
        margin: 0;
        width: 50%
      }

      &:first-child {
        @include respond-to(small) {
          /*padding-right: 25px;*/
        }
      }

      &:last-child {
        @include respond-to(small) {
          /*padding-left: 35px;*/
        }
      }

      &:first-child:after {
        @include respond-to(small) {
          content: "";
          display: inline-block;
          position: absolute;
          height: 98%;
          right: 0;
          top: 2%;
        }
      }

      h3 {
        color: $black;
        line-height: 1;
        margin: 0 0 25px;
      }
    }

    h3 {
      font-size: 36px;
    }

    .excerpt {
      color: $grey;
      font-size: 22px;
      text-align:center;
      line-height: 1.8em;
    }

    .explore-blog {
      font-weight: 700;
      margin-top: 60px;
      background-color:$blue_dark;
      color:$blue;
    }
  }

  .st3-strip {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding:20px 10%;
    background-color:$white;


    .home-strip{
      align-items: center;
      display: flex;
      flex-direction: column;
      max-width:100%;
    }

    h2 {
      margin-bottom: 50px;
      font-weight: normal;
      line-height:1.4;

      span {
        color: $blue;
        font-weight: normal;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      max-width:100%;

      @include respond-to(small) {
        flex-direction: row;
      }
    }

    .text-1, .text-3 {
      margin: 25px 0;
      position: relative;
      text-align: center;
      width:40%;


      @include respond-to(small) {
        margin: 0;
      }

      &:first-child {
        @include respond-to(small) {
          /*padding-right: 25px;*/
        }
      }

      &:last-child {
        @include respond-to(small) {
          /*padding-left: 35px;*/
        }
      }

      &:first-child:after {
        @include respond-to(small) {
          content: "";
          display: inline-block;
          position: absolute;
          height: 98%;
          right: 0;
          top: 2%;
        }
      }

      h3 {
        color: $black;
        line-height: 1;
        margin: 0 0 25px;
      }
    }
    .text-2{
      background-repeat: no-repeat;
      background-position: center;
      -webkit-background-size: contain;
      background-size: contain;
      width:20%;
    }

    h3 {
      font-size: $m-subsection-heading;
      font-weight:400;
      text-transform: none;
    }

    .excerpt {
      color: $grey;
      font-size: 20px;
    }

    .explore-blog {
      font-weight: 700;
      margin-top: 60px;
      background-color:$blue_dark;
      color:$blue;
      margin-bottom:50px;
    }
  }


  // blog feed
  .blog-feed {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 70px;

    h2 {
      margin-bottom: 50px;
      font-weight:normal;
/*      line-height: 42px;*/

      span {
        color: $blue_dark;
        font-weight:normal;
      }
    }

    .posts {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
      @include respond-to(small) {
        flex-direction: row;
      }
    }

    .post {
      margin: 25px 0;
      position: relative;
      text-align: center;

      @include respond-to(small) {
        margin: 0;
        width: 50%;
      }

      &:first-child {
        @include respond-to(small) {
          padding-right: 25px;
        }
      }

      &:last-child {
        @include respond-to(small) {
          padding-left: 35px;
        }
      }

      &:first-child:after {
        @include respond-to(small) {
          content: "";
          display: inline-block;
          position: absolute;
          border-right: 1px solid gray;
          height: 98%;
          right: 0;
          top: 2%;
        }
      }

      h3 {
        color: $black;
        line-height: 1;
        margin: 0 0 25px;
      }
    }

    h3 {
      font-size: 36px;
    }

    .excerpt {
      color: $grey_dark;
      font-size: 24px;
    }

    .explore-blog {
      font-weight: 700;
      margin-top: 60px;
      background-color:$blue_dark;
      color:$blue;
    }
  }

  // contact banner
  .contact {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 578px;
    text-align: center;
    background-size:cover;
    background-position-x: center;

    h2 {
      margin: 0;
      margin-top: 4%;
      color:$grey_dark;
      font-weight:normal;

      span{
        color:$blue;
        font-weight:normal;
        margin:10px;
      }
    }

    a {
      font-size: 35px;
      line-height: 1em;
      position: relative;
      display: inline-block;
      width: 80px;
      top:10px;
    }

    .fa-chevron-right {
      color: $blue;
      position: relative;
      z-index: 2;
      left: 18px;
      font-size: 44px;
      top:-14px;
    }

    a:after {
      background: $blue_dark;
      color:$blue;
      border-radius: 35px;
      content: "";
      line-height:1em;
      display: inline-block;
      height: 50px;
      left: 30px;
      position: absolute;
      top: -11px;
      width: 50px;
      z-index: 1;
      top:-8px;
    }
  }

// mobile strips

  @include respond-to(small-phone) {
  .st2-strip .text-1{
    margin-top:-120px !important;
  }
  }


  @include respond-to(tablet) {

    .home-strip{
      max-width:100%;
      .info{
        max-width:100%;
      }
    }

    .st1-strip {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      background-color: $grey_lighter;

      h2 {
        margin-bottom: 50px;
        color: $blue;
        font-weight: normal;
        line-height: 1.4;

        span {
          color: $blue_dark;
          font-weight: normal;
        }
      }

      .info {
        display: flex;
        flex-direction: column;

      }

      .text-1 {
          margin: 0;
          width: 100%;
        order:1;
      }
      .text-2 {
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y:bottom;
        background-position-x:center;
        height:600px;
        margin: 0;
        margin-top:-120px !important;
        margin-bottom:-30px !important;
        width: 100%;
        order:2;
      }

      .text-1, .text-2 {
        margin: 25px 0;
        position: relative;
        text-align: left;


        &:first-child:after {
            content: "";
            display: inline-block;
            position: absolute;
            height: 98%;
            right: 0;
            top: 2%;
        }

        h3 {
          color: $black;
          line-height: 1;
          margin: 0 0 25px;
        }
      }

      h3 {
        font-size: 36px;
      }

      .excerpt {
        color: $grey_dark;
        text-align: center;
        text-align:left;
      }

      .explore-blog {
        font-weight: 700;
        margin-top: 60px;
      }
    }
    .st2-strip {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 80px 20px 70px;
      position:relative;

      .home-strip{
        position:static;
      }

      h2 {
        margin-bottom: 50px;
        font-weight: normal;
        line-height: 1.4;

        span {
          font-weight: normal;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        position: static;
      }

      .text-2 {
        margin: 0;
        width: 100%;
        order: 1;
        position: static;
      }
      .text-1 {
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y:bottom;
        height:600px;
        margin: 0;
        width: 100%;
        margin-bottom: 0px !important;
        order: 2;
        position: static;
      }

      .text-1, .text-2 {
        margin: 25px 0;
        margin: 0;
        text-align: left;


        &:first-child:after {
          content: "";
          display: inline-block;
          position: absolute;
          height: 98%;
          right: 0;
          top: 2%;
        }

        h3 {
          line-height: 1;
          margin: 0 0 25px;
        }
      }

      h3 {
        font-size: 36px;
      }

      .excerpt {
        font-size: 22px;
        text-align: center;
        line-height: 1.8em;
        text-align:left;
      }

      .explore-blog {
        font-weight: 700;
        margin-top: 0px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index:9;
      }
    }

    .st3-strip {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 80px 20px 70px;
      position: relative;


      .home-strip{
        position:static;
      }

      h2 {
        margin-bottom: 50px;
        font-weight: normal;
        line-height: 1.4;

        span {
          font-weight: normal;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        position: static;
      }

      .text-3 {
        margin: 0;
        width: 100%;
        order: 2;
        position: static;
        text-align:left;
      }
      .text-1 {
        margin: 0;
        width: 100%;
        order: 1;
        position: static;
      }

      .text-1, .text-3 {
        margin: 25px 0;
        margin: 0;
        text-align: left;


        &:first-child:after {
          content: "";
          display: inline-block;
          position: absolute;
          height: 98%;
          right: 0;
          top: 2%;
        }

        h3 {
          line-height: 1;
          margin: 0 0 25px;
        }
      }

      .text-2{
        margin: 0;
        width: 100%;
        order: 3;
        position: static;
        background-repeat: no-repeat;
        background-size: contain;
        background-position:center;
        height:600px;
        margin-bottom: -70px !important;

      }

/*      h3 {
        font-size: 20px;
      }*/

      .excerpt {
        font-size: 22px;
        text-align: center;
        line-height: 1.8em;
        text-align:left;
      }

      .explore-blog {
        font-weight: 700;
        margin-top: 0px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index:9;
      }
    }

  }

}
