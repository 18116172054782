.banner {
  align-items: center;
  background: $blue_dark;
  color: $white;
  display: flex;
  padding: 50px 0;
  
  @include respond-to(small) {
    height: 230px;
  }
  
  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    @include respond-to(small) {
      flex-direction: row;
      padding: 0 40px 0 60px;
    }
  }

  
  h2 {
    font-size: 32px;
    margin: 0 0 25px;
    text-transform: none;
    
    @include respond-to(small) {
      font-size: 60px;
      margin: 0;
    }
  }
}

.footer-banner {
  padding: 25px 15px;

  @include respond-to(small) {
    padding: 0;
  }

  h2 {
    color: $white;
    margin: 0 0 15px;

    @include respond-to(small) {
      font-size: 60px;
      margin: 0;
    }
  }
}
