.blog,
.archive.category,
.archive.tag {
	.category-nav {
		padding: 100px 0 50px;
		text-align: center;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {
			display: inline-block;
		}

		a {
			background: $blue_dark;
			font-weight: 700;

			&:hover {
				background: $blue;
			}

			&:after {
				content: "\f054";
				display: inline-block;
				font-family: 'Font Awesome 5 Pro';
				font-size: 14px;
				font-weight: 500;
				margin-left: 8px;
				position: relative;
				top: -2px;
			}
		}
	}

	.tag-picker {
		display: flex;
		height: 68px;
		justify-content: center;
		margin-bottom: 100px;
		flex-wrap:wrap;
		@include respond-to(extra-small){
			flex-wrap: nowrap;
		}

		.tag-filter-wrapper {
			position: relative;
			width:80%;

			@include respond-to(extra-small){
				width:40%
			}

			&:after {
				color: $grey_dark;
				content: "\f078";
				font-family: 'Font Awesome 5 Pro';
				font-weight: 700;
				position: absolute;
				right: 25px;
				top: 50%;
				transform: translateY(-50%);
			}
			@include respond-to(small) {
				width: 33%;
			}
		}

		select {
			background: none;
			border-image: none;
			border-radius: 0;
			border-style: solid;
			border-color: $grey_dark;
			border-right: none;
			box-shadow: none;
			color: $grey_dark;
			font-size: 20px;
			padding-left: 25px;
			-webkit-appearance: none;
			width:100%;
			height:60px;
			font-size: $m-text-small;
			font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
			font-weight: 700;
			@include respond-to(extra-small) {
				height:68px;
			}

		}

		button {
			font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
			font-weight: 700;
			height: 100%;
			outline: none;
			font-size: $m-text-small;
		}

		button {
			background: $grey_dark;
			border: 1px solid $grey_dark;
			width: 80%;
			@include respond-to(extra-small){
				width:40%;
			}

			@include respond-to(small) {
				width: auto;
			}

			&:hover {
				background: $blue_dark;
			}
		}
	}
}


.blog,
.archive.author,
.archive.category,
.archive.tag {
	.posts-feed {
		margin: 0 auto;
		padding: 0 30px;
		
		@include respond-to(small) {
			display: grid;
			grid-column-gap: 35px;
			grid-row-gap: 50px;
			grid-template-columns: 1fr 1fr;
		}

		@include respond-to(large) {
			max-width: 1163px;
			padding: 0;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	.tease-post {
		h2 {
			font-size: 24px;
			line-height: 1.5;
			text-align: left;
			text-transform: none;
			
			@include respond-to(small) {
				font-size: 30px;
			}
			
			a {
				color: $blue_dark;
			}

			&:hover {
				text-decoration: underline;
			}
		}

		p {
			color: $grey_dark;
			font-size: 18px;
			line-height: 1.9175;
			
			@include respond-to(small) {
				font-size: 24px;
			}
		}

		.tags-list {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
				text-transform: uppercase;

				a {
					color: $blue;
					font-size: 18px;
					font-weight: 700;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			li:not(:last-child) {
				&:after {
					content: "";
					border-right: 1px solid $grey_dark;
					display: inline-block;
					height: 15px;
					margin-right: 5px;
					padding-right: 10px;
					position: relative;
					top: 3px;
				}
			}
		}

		.read-more {
			color: $grey_dark;
			font-size: 16px;
			padding-left: 5px;
			position: relative;
			top: -3px;
		}

		.byline {
			color: $grey_dark;
			display: flex;
			font-weight: 700;
			justify-content: space-between;
			position: relative;
		}

		.author-headshot {
			position: absolute;
			left: 15px;
			top: -35px;
			width: 65px;

			img {
				border: 1px solid $grey_dark;
				border-radius: 100%;
				display: block;
			}
		}

		.author {
			margin-left: 95px;

			.name {
				color: $blue;
			}
		}
	}

	.author-info {
		align-items: center;
		border-bottom: 2px solid;
		color: $grey_dark;
		display: flex;
		flex-direction: column;
		margin: 95px auto 50px;
		padding: 0 100px 50px;
		width: 10 / 12 * 100%;

		@include respond-to(small) {
			align-items: flex-start;
			flex-direction: row;
		}

		img {
			border-radius: 100%;
			margin-right: 40px;
			max-width: 160px;
		}

		.author-name {
			color: $blue_dark;
			font-size: 30px;
			font-weight: 700;
			margin: 0;
			text-transform: uppercase;
		}

		p {
			margin: 0;
		}
	}

	.pagination {
		color: $grey_dark;
		font-size: 24px;
		list-style: none;
		margin: 0;
		padding: 80px 0 0;
		text-align: center;
		
		li {
			display: inline-block;
			
			&:before {
				border-left: 2px solid $grey_dark;
				content: "";
				display: inline-block;
				height: 22px;
				margin-left: 10px;
				padding-left: 10px;
				position: relative;
				top: 4px;
			}
			
			&:nth-child(-n+2),
			&:last-child {
				&:before {
					border-left: none;
					margin-left: 0;
					padding-left: 0;
				}
			}
		}

		.current {
			color: $black;
		}

		.disabled {
			a {
				color: $grey_dark;
			}
		}

		.fa-chevron-right,
		.fa-chevron-left {
			font-size: 14px;
			position: relative;
			top: -2px;
		}

		.fa-chevron-left {
			margin-right: 5px;
		}

		.fa-chevron-right {
			margin-left: 5px;
		}
		
		a {
			color: $grey_dark;
			font-weight: 700;

			&:hover {
				color: $black;
			}
		}
	}

}


.blog,
.archive.author,
.archive.category,
.archive.tag,
.single-post {
	.signup-banner {
		background: $blue;
		color: $white;
		margin: 95px 0 0;
		padding: 25px 0;

		._form-title {
			font-size: 36px;
			font-weight: 700;
			line-height: 48px;
			margin: 0 0 10px;
			text-transform: uppercase;
		}

		.inputs-wrapper {
			display: inline-flex;
		}
		
		h2,
		.embedded-form {
			margin-left: auto;
			margin-right: auto;
			max-width: 840px;
			text-align: center;
		}

		.embedded-form {
			._form-content {
				font-size: 0;
				padding: 0 30px;

				@include respond-to(small) {
					padding: 0;
				}
			}

			form {
				background: transparent;
			}

			label {
				display: none;
			}

			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: $grey_dark;
				font-size: 20px;
			}
			::-moz-placeholder { /* Firefox 19+ */
				color: $grey_dark;
				font-size: 20px;
			}
			:-ms-input-placeholder { /* IE 10+ */
				color: $grey_dark;
				font-size: 20px;
			}
			:-moz-placeholder { /* Firefox 18- */
				color: $grey_dark;
				font-size: 20px;
			}

			button {
				background: $blue_dark;
				font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
				font-weight: 700;

				&:hover {
					background: $grey_dark;
				}

				&:after {
					content: "\f054";
					display: inline-block;
					font-family: "Font Awesome 5 Pro";
					font-size: 13px;
					font-weight: 400;
					line-height: 1;
					margin-left: 4px;
					position: relative;
					top: -2px;
				}
			}

			button,
			input {
				height: 68px;
				width: 100%;
				line-height: 30px;
			}

			._button-wrapper {
				display: inline-block;
			}
			
			._x59777536 {
				display: inline-block;
				width: 398px;
			}

			.inputs-wrapper {
				max-width: 100%;
			}
			
			input[type=text] {
				color: $grey_dark;
				padding: 6px 0 0 15px;
			}
		}
	}
}
