.single-service,
.single-marketing_service,
.single-website_service,
.single-our_work,
.single-resource {
	.intro {
		background: $blue_dark;
		color: $white;
		padding: 80px 0;
		text-align: center;
	}

	.page-nav {
		ul {
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			justify-content: flex-start;
			margin: 0;
			padding: 0;

			@include respond-to(small) {
				justify-content: center;
			}
		}

		li {
			width: 100%;

			@include respond-to(extra-small) {
				width: 50%;
			}

			@include respond-to(small) {
				margin-right: 90px;
				max-width: 200px;
				width: auto;
			}
			
			&:last-child {
				margin-right: 0;
			}
		}

		.title {
			color: $green;
			font-size: 24px;
			font-weight: 700;
			line-height: 1.05;
			text-transform: uppercase;
			
			@include respond-to(small) {
				font-size: 30px;
			}
		}

		.icon {
			margin: 35px 0;
			height: 100px;
			text-align: center;

			img {
				width: 100px;
	
				@include respond-to(small) {
					height: 114px;
					width: 114px;
				}
			}
		}
	}


	// main content
	.main-content {
		color: $grey_dark;

		h2,
		h3 {
			color: $blue_dark;
		}

		h2 {
			font-size:$m-hero-heading;
			margin: 80px 0 40px;
		}

		h3 {
			font-size: 30px;
			line-height: 46px;
			margin: 1.9em 0 0;
			text-align: left;
		}

		p {
			margin: 0 0 1em;
		}
	}


	// gallery
	.gallery {
		h2 {
			background: $blue_dark;
			color: $white;
			margin: 125px 0 75px;
			padding: 50px 0;
		}

		.slick-slide {
			box-shadow: 0px 13px 29px 0px rgba(156, 161, 162, 0.35); 
			transition: transform 0.25s linear;
		}

		.slick-track {
			padding: 100px 0;
		}

		.slick-center.slick-active {
			transform: scale(1.25);
		}

		.slick-next,
		.slick-prev {
			background-color: transparent;
			background-size: contain;
			border: none;
			font-size: 0;
			height: 70px;
			position: absolute;
			width: 70px;
			top: 50%;
			transform: translateY(-50%);
		}
		
		.slick-next {
			background-image: url(/wp-content/themes/vetmethod/assets/images/chevron-button.png);
			right: -80px;
		}
		
		.slick-prev {
			background-image: url(/wp-content/themes/vetmethod/assets/images/chevron-button-left.png);
			left: -80px;
		}

		.button {
			align-items: center;
			background: $blue_dark;
			display: flex;
			font-weight: 700;
			height: 68px;
			justify-content: center;
			margin: auto;
			padding: 0 15px;
			width: 215px;

			&:after {
				content: "\f054";
				display: inline-block;
				font-family: 'Font Awesome 5 Pro';
				font-size: 14px;
				font-weight: 400;
				margin: 2px 0 0 15px;
			}
		}
	}


	// our process
	.our-process {
		margin: 60px 0 100px;

		.row {
			display: flex;
			min-height: 500px;

			&:first-child {
				.block {
					&:last-child {
						order: -1;
					}
				}
			}

			.block {
				align-items: center;
				background-size: cover;
				color: $white;
				display: flex;
				flex-direction: column;
				font-size: $m-text-small;
				font-weight: 700;
				padding:0px 10px;
				justify-content: center;
				width: 100%;

				&:last-child {
					background: $green;
				}
			}
		}

		h3 {
			color: $blue_dark;
			font-size: $m-subsection-heading;
			margin: 0 0 0.75em;
		}

		p {
			max-width: 500px;
		}
	}


	// testimonial
	.testimonial {
		margin-bottom: 100px;

		.container {
			display: flex;
			flex-direction: column;
	
			@include respond-to(small) {
				flex-direction: row;
			}
		}

		.column {
			&:first-child {
				@include respond-to(small) {
					text-align: center;
					width: 6 / 12 * 100%;
				}
			}

			&:last-child {
				@include respond-to(small) {
					width: 6 / 12 * 100%;
				}
			}
    }
    
    .name {
			color: $blue_dark;
			font-size: 30px;
			text-align:center;
		}
		
		.name,
		.business,
		.url,
		.quote {
			text-align:center;
		}

		.business,
		.url {
			font-size: 20px;
			text-align:center;
		}

		.business {
			color: $grey_dark;
			text-transform: uppercase;
			text-align:center;
		}

		.url {
			a {
				color: $green;
			}
		}

    .quote {
      font-size: $m-text;
			line-height: 1.8;
      margin: 0 auto 40px;
		text-align:center;
		max-width: 500px;
		@include respond-to(small) {
			text-align:left;
		}
    }
  }


	// FAQs
	.faqs {
		background: $blue_dark;
		color: $white;
		padding: 35px 0 115px;

		.question-group {
			align-items: flex-start;
			border: 1px solid $white;
			border-bottom: none;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			min-height: 105px;
			
			&:last-child {
				border-bottom: 1px solid $white;
			}
			
			&.active {
				.answer {
					display: block;
				}

				.question {
					background: $blue_dark;
				}
			}
		}

		.question,
		.answer {
			padding-left: 35px;
			padding-right: 35px;
			width: 100%;
		}
		
		.question {
			align-items: center;
			background: $green;
			cursor: pointer;
			display: flex;
			font-size: 24px;
			font-weight: 700;
			justify-content: flex-start;
			min-height: 105px;
			padding-top: 10px;
			padding-bottom: 10px;
			position: relative;
			text-transform: uppercase;
			
			@include respond-to(small) {
				font-size: 36px;
				padding-top: 0;
				padding-bottom: 0;
			}

			&:after {
				content: "\f054";
				display: inline-block;
				font-family: "Font Awesome 5 Pro";
				position: absolute;
				right: 35px;
			}
		}

		.answer {
			background: $white;
			color: $grey_dark;
			font-size: 30px;
			font-weight: 700;
			display: none;
			padding-top: 25px;
			padding-bottom: 45px;
		}
	}


	// resources
	.resources {
		h2 {
			color: $blue_dark;
			margin: 100px 0 0;
		}

		.intro-copy {
			color: $grey_dark;
			text-align: center;
		}

		.resources-feed {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			margin: 45px 0 0;

			@include respond-to(small) {
				flex-direction: row;
				justify-content: space-between;
				margin: 45px 0 50px;
			}
		}

		.resource {
			margin: 0 0 50px;
			@include respond-to(small) {
				width: 48%;
			}
		}

		.title {
			color: $blue_dark;
			font-size: 24px;
			text-transform: uppercase;
			font-weight: 700;
		}

		.summary {
			color: $grey_dark;
			margin: 0 0 1em;
			font-size: 20px;

			@include respond-to(small) {
				min-height: 114px;
			}
		}

		.button {
			align-items: center;
			display: flex;
			font-weight: 700;
			height: 68px;
			justify-content: center;
			padding: 0;
			width: 180px;

			&:after {
				content: "\f054";
				display: inline-block;
				font-family: 'Font Awesome 5 Pro';
				font-size: 14px;
				font-weight: 500;
				margin-left: 10px;
			}
		}
	}


	// footer banner
	.footer-banner {
		background: $green;

		@include respond-to(small) {
			align-items: center;
			display: flex;
			justify-content: center;
			min-height: 230px;
		}

		.button {
			align-items: center;
			background: $blue_dark;
			display: flex;
			font-size: 20px;
			font-weight: 700;
			margin: auto;
			min-height: 68px;
			width: 200px;
			
			@include respond-to(small) {
				margin: 0 0 0 60px;
				width: auto;
			}

			&:after {
        content: "\f054";
        font-family: "Font Awesome 5 Pro";
        font-size: 13px;
        font-weight: 400;
        line-height: 1;
				margin-left: 10px;
				padding-top: 1px;
      }
		}
	}
}
