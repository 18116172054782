.page-template-team {

	.team{}
	.team {
		display: grid;
		grid-column-gap: 20px;
		grid-template-columns: repeat(1, 1fr);
		margin-bottom: 80px;
		margin-top: 80px;
		position: relative;
		
		@include fadeIn;

		@include respond-to(extra-small){
			grid-template-columns: repeat(2, 1fr);
		}

		@include respond-to(small) {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	.team-member {
		cursor: pointer;

		&:hover {

			.headshot {
				opacity: 1;
				/*opacity: 0.8;*/
			}

			.name {
				color: $green;
			}
		}
		
		.initial-content {
			padding: 0 0 40px;
			text-align: center;
		}

		.name {
			color: $blue_dark;
			font-size: $m-subsection-heading;
			font-weight: 700;
			margin: 20px 0 0;
			text-transform: uppercase;
		}

		.job-title {
			color: $grey_dark;
			font-size: $m-subsection-block-heading;
			font-weight: 700;
		}

		.headshot {
			img {
				border: 1px solid $grey;
			}
		}
		
		&.active {
			opacity: 1;

			.name {
				color: $green;
			}
		}
	}
	
	.expanded-bio {
		color: $white;
		cursor: default;
		background: $blue;
		display: none;
		flex-direction: column;
		font-weight: 700;
		margin: 0 0 30px;
		padding: 50px;
		position: absolute;
		width: 100%;

		@include respond-to(small) {
			flex-direction: row;
		}

		.column {
			&:first-child {
				text-align: center;
				
				@include respond-to(small) {
					padding: 0 0 40px;
					width: 5 / 12 * 100%;
				}
			}
			
			&:last-child {
				
				@include respond-to(small) {
					margin-left: 50px;
					width: 7 / 12 * 100%;
				}
			}
			
			.name {
				color: $black;
				font-size: 36px;
				margin: 0 0 10px;
			}
		}

		.headshot {
			margin: 0 0 40px;
			
			@include respond-to(small) {
				margin: 0 0 80px;
			}
		}
		
		
		.job-title,
		.articles a {
			color: $white;
			font-size: $m-subsection-heading;
		}

		.job-title {
			margin-bottom: 45px;
		}

		.articles a {
			display: inline-flex;
			align-items: center;

			&:after {
				content: "\f054";
				font-family: 'Font Awesome 5 Pro';
				font-weight: 400;
				font-size: 17px;
				line-height: 1;
				padding: 5px 0 0 10px;
			}
		}

		.bio,
		.fun-fact {
			font-size: $m-text;
			line-height: 1.91;
		}

		.articles {
			font-size: $m-subsection-block-heading;
		}

		.fun-fact {
			h3 {
				color: $black;
				font-size: $m-subsection-heading;
				margin: 50px 0 0;
				text-align: left;
			}
		}
	}

}