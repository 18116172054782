.page-template-template-what-sets-us-apart {
	.container,
	.content-section {
		@include respond-to(large) {
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.intro-content {
		padding: 115px 30px 0;
		
		@include respond-to(small) {
			padding: 115px 0 0;
		}

		h2 {
			color: $blue;
			@include respond-to(small) {
			font-size:$d-heading;
			}
		}


		p {
			color: $grey_dark;
			text-align: center;
		}
	}

	.stats {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 75px 0 95px;

		&.activated {
			.value {
				opacity: 1;
			}
		}

		.stat {
			text-align: center;
			width: 100%;

			@include respond-to(small) {
				width: auto;
			}
		}

		.label,
		.value {
			font-weight: 700;
		}

		.value {
			color: $blue;
			font-size: 80px;
			/*font-size: 80px;*/
			opacity: 0;
			transition: opacity 0.35s linear;
		}

		.label {
			font-size: 30px;
			text-transform: uppercase;
		}
	}

	.list-section {
		background: $blue_dark;
		color: $white;
		margin-bottom: 95px;
		padding: 90px 0 0;

		h2 {
			color: $blue;
			@include respond-to(small) {
				font-size:$d-heading;
			}
		}

		.list-copy {
			font-weight: 700;
			text-align: center;
		}

		ul {
			display: grid;
			grid-template-columns: 1fr 1fr;
			list-style: none;
			margin: 80px 0 0;
			padding: 0;
			
			@include respond-to(small) {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
		}

		li {
			border: 1px solid $white;
			padding: 45px 0 38px;
			text-align: center;
			
			a {
				color: $white;
			}
		}

		.icon {
			margin: auto;
			max-width: 132px;
		}
	}

	#content-section {
		@include respond-to(small) {
			@include slideLeft;
		}
	}

	.content-section {
		margin-bottom: 105px;
		padding: 0 30px;

		@include respond-to(small) {
			padding: 0;
		}
	}

	.content-section, {

		h2,
		.heading {
			font-size:$m-heading;
			font-weight: 700;
			text-align: center;
			text-transform: uppercase;

			@include respond-to(small){
				font-size: $d-subsection-subheading;
			}
			span {
				color: $blue;
				display: inline-block;
				font-size: $m-heading;
				line-height: 1;
				margin-top: 5px;
				@include respond-to(small){
					font-size: $d-heading;
				}
			}


		}

		.copy {
			color: $grey_dark;
			font-weight: 700;
			text-align: center;
		}
	}

	.tiles-section {

		h2,
		.heading {
			font-size:$m-heading;
			font-weight: 700;
			text-align: center;
			text-transform: uppercase;

			@include respond-to(small){
				font-size: $d-subsection-subheading;
			}
			span {
				color: $blue;
				display: inline-block;
				font-size: $m-heading;
				line-height: 1;
				margin-top: 5px;
				@include respond-to(small){
					font-size: $d-heading;
				}
			}


		}

		.copy {
			color: $grey_dark;
			font-weight: 700;
			text-align: center;
		}
	}

	.tiles-section {
		.tiles {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 40px;
		}

		.tile {
			background: $blue_dark;
			color: $white;
			margin-bottom: 36px;
			padding: 40px 30px 45px;
			
			@include respond-to(small) {
				padding: 80px 40px 90px;
				width: 48.5%;
			}

			&:nth-child(3n+1) {
				background: $blue;

				.heading {
					color: $blue_dark;
				}
			}

			.heading {
				color: $blue;
				margin-bottom: 25px;
				
				@include respond-to(small) {
					margin-bottom: 45px;
				}
			}

			.copy {
				color: $white;
			}
		}
	}

	// testimonials
  .testimonials {
    display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 105px;
    padding-top: 60px;
    padding-bottom: 50px;

    @include respond-to(small) {
      flex-direction: row;
    }

    .column {
			&:first-child {
				@include respond-to(small) {
					text-align: center;
					width: 5 / 12 * 100%;
				}
			}

			&:last-child {
				@include respond-to(small) {
					width: 7 / 12 * 100%;
				}
			}
    }
    
    .name {
			color: $blue_dark;
			font-size: 30px;
		}
		
		.name,
		.business,
		.url,
		.quote {
			font-weight: 700;
		}

		.business,
		.url {
			font-size: 20px;
		}

		.business {
			color: $grey_dark;
			text-transform: uppercase;
		}

		.url {
			a {
				color: $blue;
			}
		}

    .quote {
      color: $grey_dark;
      font-size: 20px;
      font-weight: 700;
		line-height: 1.8;
      margin-bottom: 40px;
    }

    .video-embed.placeholder {
			background-image: url(/wp-content/themes/vetmethod/assets/images/video_placeholder_2.jpg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 217px;
		}

    .button {
      &:hover {
        background: $blue_dark;
      }
    }
  }

	.footer-banner {
		background: $blue;

		@include respond-to(small) {
			align-items: center;
			display: flex;
			justify-content: center;
			min-height: 230px;
		}

		.button {
			align-items: center;
			background: $blue_dark;
			display: flex;
			font-size: 18px;
			font-weight: 700;
			margin: auto;
			min-height: 68px;
			width: 200px;
			
			@include respond-to(small) {
				margin: 0 0 0 60px;
			}

			&:after {
        content: "\f054";
        font-family: "Font Awesome 5 Pro";
        font-size: 13px;
        font-weight: 300;
        line-height: 1;
				margin-left: 10px;
				padding-top: 1px;
      }
		}
	}
}
