button,
.button {
  background: $green;
  border: 1px solid $grey;
  color: $white;
  display: inline-block;
  font-size: 20px;
  padding: 10px 35px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.25s linear;
}
