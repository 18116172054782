// global
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  text-transform: uppercase;
}

h2 {
  font-size: 32px;
  
  @include respond-to(small) {
    font-size: 60px;
  }
}


// home page
.home,
.page-template-template-careers {
  h2 {
/*    font-size: 30px;*/
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    
    span {
      color: $blue_dark;
  /*    font-size: 54px;*/

    }
  }
}

.hero{
  .h2{
    line-height:1.2;
    color: $blue_dark;
    span{
      color:$blue_light;
    }
  }
}

h2{
  font-size:$m-subhead;
  span{
    font-size:$m-heading;
  }
}

.heading{
  font-size:$m-text;
}

//desktop
@include respond-to(small) {
  h1 {
    font-size:$d-hero-subhead;
    span {
      letter-spacing: 2px;
      font-size:$d-hero-heading
    }
  }

  .hero{
    .h2{
      line-height:1.2;
    }
  }

  h2{
    font-size:$d-subhead;
    span{
      font-size:$d-heading;
    }
  }

  .heading{
    font-size:$d-text;
  }

}