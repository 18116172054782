html {
	* {
		box-sizing: border-box;
	}
}

@mixin container {
	max-width: 100%;
	padding: 0 30px;
	width: 100%;

	@include respond-to(small) {
		padding: 0;
	}

	@include respond-to(medium) {
		margin: 0 auto;
		max-width: 992px;
	}

	@include respond-to(large) {
		max-width: 1200px;
	}
}

.container {
	@include container;
}

.mobile-only {
	@include respond-to(small) {
		display: none;
	}
}
