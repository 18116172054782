.page-template-template-design-gallery,
.page-template-template-logo-design-gallery,
.page-template-template-template-design-gallery {
	.article-body {
		color: $grey_dark;
		text-align: center;
	}

	.intro {
		padding: 105px 15px 90px;
		
		@include respond-to(small) {
			padding: 105px 0 90px;
		}
	}

	.projects {
		margin: auto;

		@include fadeIn;
		
		@include respond-to(small) {
			display: flex;
			flex-wrap: wrap;
		}
		
		@include respond-to(large) {
			max-width: 2000px;
		}
	}

	.project {
		border-top: 1px solid $grey_dark;
		
		@include respond-to(small) {
			border: 1px solid $grey_dark;
			width: 50%;
		}

		&:nth-child(even) {
			@include respond-to(small) {
				border-left: none;
			}
		}

		&:nth-child(n+3) {
			@include respond-to(small) {
				border-top: none;
			}
		}
		
		.container {
			min-height: 350px;
			position: relative;
			
			@include respond-to(small) {
				min-height: 425px;
			}
			
			@include respond-to(medium) {
				min-height: 500px;
			}
		}

		.image {
			align-items: center;
			display: flex;
			justify-content: center;

			img {
				border: 1px solid $grey_dark;
				width: 68%;
			}
		}
		
		.image,
		.overlay {
			height: 100%;
			left: 0;
			position: absolute;
			width: 100%;
		}

		.overlay {
			align-items: center;
			background: rgba($blue_dark, 0.75);
			color: $white;
			cursor: pointer;
			display: none;
			font-size: 54px;
			font-weight: 700;
			justify-content: center;
			text-transform: uppercase;
		}

		&:hover {
			.overlay {
				display: flex;
			}
		}
	}

	.footer-banner {
		background: $green;

		@include respond-to(small) {
			align-items: center;
			display: flex;
			justify-content: center;
			min-height: 230px;
		}

		.button {
			align-items: center;
			background: $blue_dark;
			display: flex;
			font-size: 20px;
			font-weight: 700;
			margin-left: auto;
			margin-right: auto;
			min-height: 68px;
			width: 200px;
			
			@include respond-to(small) {
				margin-left: 60px;
				margin-right: 0;
			}

			&:after {
        content: "\f054";
        font-family: "Font Awesome 5 Pro";
        font-size: 13px;
        font-weight: 300;
        line-height: 1;
				margin-left: 10px;
				padding-top: 1px;
			}
			
			&:hover {
				background: $white;
				color: $blue_dark;
			}
		}
	}
}
