body,
html {
  color: $black;
  margin: 0;
  padding: 0;
}

embed {
  max-width: 100%;
}
