.page-template-template-contact {
	width: 100%;

	// intro content
	.post-content {
		color: $grey_dark;
		margin: 112px auto 50px;
		text-align: center;
		
		p {
			/*font-size: 30px;*/
		}
	}

	.article-body {
		.container {
			@include respond-to(large) {
				max-width: 1070px;
			}
		}
	}


	// form and sidebar
	.form-and-sidebar {
		display: flex;
		flex-direction: column;

		@include respond-to(small) {
			flex-direction: row;
		}
	}

	// sidebar
	.sidebar {
		@include respond-to(small) {
			width: 4 / 12 * 100%;
		}

		.section{
			margin: 0 0 50px;
			width: 100%;
			padding-left:15px;
		}
		.section:last-of-type{
			margin: 0;
		}

		.phone,
		.fax,
		.offices,
		.hours {
			position: relative;
			padding-left: 67px;

			&:before {
				content: "";
				background-repeat: no-repeat;
				background-size: contain;
				display: inline-block;
				height: 67px;
				position: absolute;
				left: 0px;
				width: 67px;
			}
		}

		.phone {
			&:before {
				background-image: url(/wp-content/themes/vetmethod/assets/images/contact_phone.png);
				float:left;
			}
		}

		.fax {
			&:before {
				background-image: url(/wp-content/themes/vetmethod/assets/images/contact_fax.png);
				float:left;
			}
		}

		.offices {
			&:before {
				background-image: url(/wp-content/themes/vetmethod/assets/images/contact_map-pin.png);
				float:left;
			}
			.denver {
				margin:0 0 0 15px;
				h3, p {
					margin: 0;
				}
			}
		}

		.hours {
			&:before {
				background-image: url(/wp-content/themes/vetmethod/assets/images/contact_clock.png);
				float:left;
			}
		}

		h3 {
			color: $blue;
			margin:0 0 0 15px;
			text-align: left;
		}

		p {
			color: $grey_dark;
			margin:0 0 0 15px;
			text-align:left;
		}

		a {
			color: $grey_dark;
		}

		.australia {
			a {
				color: $blue;
				font-weight: 700;
				text-transform: uppercase;
			}
		}
	}

	// form
	.form-embed {
		margin-bottom: 100px;
		order: -1;

		@include respond-to(small) {
			margin-bottom: 0;
			order: 0;
			width: 8 / 12 * 100%;
		}

		form {
			width: 100%;
		}

		input {
			border: 1px solid $grey_dark;
			color: $grey_dark;
			font-size: $m-text;
			font-weight: 700;
			margin-bottom: 18px;
			height: 65px;
			padding: 22px 9px 15px;
			width: 100%;
		}

		input[type=checkbox] {
			-webkit-appearance: none;
			border: 1px solid $grey_dark;
			border-radius: 0;
			box-shadow: none;
			display: inline-block;
			height: 20px;
			margin: 0 12px 0 0;
			padding: 10px;
			position: relative;
			width: 20px;

			&:checked:after {
				content: "\f00c";
				display: inline-block;
				font-family: "Font Awesome 5 Pro";
				font-size: 16px;
				left: 2px;
				position: absolute;
				top: 2px;
			}
		}

		._form-label {
			color: $black;
			display: block;
		/*	font-size: 26px;*/
			font-weight: 700;
		}
		
		._field-141 {
			._form-label {
				margin-top: 25px;
			}
		}
		
		._checkbox-radio {
			align-items: center;
			display: flex;
			margin: 20px 0 25px;

			&:last-child {
				margin-bottom: 35px;
			}

			label {
				color: $black;
/*				font-size: 26px;*/
				font-weight: 700;
			}
		}

		button {
			border: none;
			font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
			font-size: 26px;
			height: 65px;
			font-weight: 700;
			width: 100%;
		}
	}
	
	// map
	.map-embed {
		margin: 118px 0 0;
		position: relative;

		.overlay {
			background: rgba($blue_dark, 0.4);
			height: 100%;
			position: absolute;
			width: 100%;
		}

		#map {
			height: 440px;
			width: 100%;
		}

		iframe {
			width: 100%;
		}
	}
}
