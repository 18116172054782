.nav-banner {
  width: 100%;

  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;

    @include respond-to(small) {
      flex-direction: row;
      height: 108px;
    }
  }

  li {
    align-items: center;
    background: $blue;
    display: inline-flex;
    font-size: 34px;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    text-transform: uppercase;
    width: 100%;

    @include respond-to(small) {
      width: 33.33%;
    }

    &:hover {
      background: darken($blue, 5%);
    }

    &:first-child,
    &:last-child {
      background: $green;

      &:hover {
        background: darken($green, 5%);
      }
    }
    
    a {
      align-items: center;
      color: $white;
      display: inline-flex;
      padding: 25px 0;
      height: 100%;
      line-height: 1;
      justify-content: center;
      width: 100%;

      @include respond-to(small) {
        padding: 0;
      }

      &:after {
        content: "\f054";
        font-family: "Font Awesome 5 Pro";
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        margin-left: 10px;
      }
    }
  }
}
