.page-template-template-services,
.page-template-template-marketing-services,
.page-template-template-website-services,
.page-template-template-our-work,
.page-template-template-resources {
	.services {
		@include fadeIn;
	}

	.service {
		padding: 85px 0;

		.container {
			display: flex;
			flex-direction: column;

			&:hover {
				.title {
					color: $blue_dark;
				}
			}

			@include respond-to(small) {
				flex-direction: row;
			}
		}
		
		.column {
			&:first-child {
				@include respond-to(small) {
					display: flex;
					justify-content: flex-end;
					margin-left: 1 / 12 * 100%;
					text-align: center;
					width: 3 / 12 * 100%;
				}
			}
			
			&:last-child {
				@include respond-to(small) {
					margin-right: 1 / 12 * 100%;
					padding-left: 25px;
					width: 9 / 12 * 100%;
				}
			}
		}
		
		.title {
			color: $green;
			font-size: 50px;
			font-weight: 700;
			line-height: 1;
			text-transform: uppercase;
			
			.fa.fa-chevron-right {
				display: inline;
				font-family: "Font Awesome 5 Pro";
				font-size: 32px;
				font-weight: 400;
				margin-left: 20px;
				position: relative;
				top: -5px;
			}
		}

		.copy {
			font-weight: 700;
			font-size: 20px;
			color: $grey_dark;
		}

		&:nth-child(odd) {
			background: $grey_light;
		}

		.icon {
			margin-top: 5px;
			max-width: 182px;
		}
	}
}
