p {
	font-size:$m-text;
	line-height: 1.7;
	font-weight: 400;
	margin: 1em 0;
	
	@include respond-to(small) {
		font-size: $d-text;
	}
}
