.search {
	.hero {
		height: 300px;
		min-height: 0;
	}

	.search-results {
		padding-top: 50px;
	}

	.tease {
		margin: 0 0 45px;

		h2 {
			display: inline-block;
			font-size: 30px;
			margin: 0 8px 3px 0;
			text-align: left;
			text-transform: none;
			
			a {
				color: $blue_dark;
			}
		}

		h3 {
			background: $grey_light;
			border-radius: 2px;
			color: $black;
			display: inline-block;
			font-size: 16px;
			font-weight: 400;
			margin: 0;
			padding: 2px 8px 3px;
			position: relative;
			text-transform: none;
			text-align: left;
			top: -4px;
		}

		p {
			font-weight: 400;
			margin-top: 0;
		}
	}
}
