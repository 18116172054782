// colors
$black: #000000;
$blue: #69B6C0;
$blue_light:#a5cfd5;
$blue_dark: #051548;

//$blue_dark: #2f9456;

$grey: #515a5d;
$grey_dark: #404042;
$grey_light: #9D9D9D;
$grey_lighter:#f8f6f7;

//backwards compat
$green: $blue;
$white: #ffffff;
$green_light: $grey_light;


//mobile font sizes

$m-hero-heading:37px;
$m-hero-subhead:18px;
$m-subhead:18px;
$m-heading:37px;
$m-text:17px;
$m-text-small: 15px;
$m-subsection-heading:24px;
$m-subsection-block-heading:20px;

$d-hero-heading:55px;
$d-hero-subhead:20px;
$d-subhead:20px;
$d-heading:45px;
$d-text:20px;
$d-text-small: 17px;

$d-subsection-subheading:30px;
$d-subsection-heading:24px;
$d-subsection-panel-heading:30px;
$d-subsection-block-heading:30px;

$button-desktop:18px;


/*
@include respond-to(small){
  font-size:
}*/
