/*!
 * Theme Name: Vet Method 2019
 * Description: Theme for Vet Method Website
 * Author: Chris Deaton and Max Schlatter
 * Version: 1.2
 * Description: Twig and Timber based theme
*/


/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Variables
# Mixins
# Typography
# Elements
# Forms
# Navigation
# Modules
# Templates
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Variables
--------------------------------------------------------------*/
@import "variables/_variables.scss";

/*--------------------------------------------------------------
# Mixins
--------------------------------------------------------------*/
@import "mixins/_animations.scss";
@import "mixins/_breakpoints.scss";
@import "mixins/_grid.scss";

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import "typography/_typography.scss";

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
@import "elements/_buttons.scss";
@import "elements/_elements.scss";
@import "elements/_headings.scss";
@import "elements/_images.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_paragraphs.scss";

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
@import "forms/_buttons.scss";
@import "forms/_forms.scss";
@import "forms/_inputs.scss";

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
@import "navigation/_main.scss";

/*--------------------------------------------------------------
# Modules
--------------------------------------------------------------*/
@import "modules/_banner.scss";
@import "modules/_footer_nav.scss";
@import "modules/_footer.scss";
@import "modules/_header.scss";
@import "modules/_hero.scss";
@import "modules/_nav-banner.scss";

/*--------------------------------------------------------------
# Templates
--------------------------------------------------------------*/
@import "templates/_base.scss";
@import "templates/_blog-post.scss";
@import "templates/_blog.scss";
@import "templates/_careers.scss";
@import "templates/_contact.scss";
@import "templates/_design-gallery.scss";
@import "templates/_home.scss";
@import "templates/_landing-page.scss";
@import "templates/_persona.scss";
@import "templates/_process.scss";
@import "templates/_project-single.scss";
@import "templates/_search.scss";
@import "templates/_service-single.scss";
@import "templates/_services.scss";
@import "templates/_team.scss";
@import "templates/_testimonials.scss";
@import "templates/_what-sets-us-apart.scss";
